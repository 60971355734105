export const theme = {
  PRIMARYBLUECOLOR: '#1D2E88',
  PRIMARYCOLOR: '#196AAB',
  SECONDARYBLUECOLOR: '#1C75BC',
  BOXCOLOR: '#4AA1E0',
  SEMIBLUECOLOR: '#ACD4F1',
  PRIMARYBLUECOLOR200: '#BCC6FC',
  BLUECOLOR: '#EBF2F7',
  BACKGROUNDPRIMARYBLUE: 'rgba(74, 161, 224, 0.14)',
  BACKGROUNDBLUE: ' rgba(74, 161, 224, 0.26)',
  LIGHTBLUECOLOR: '#EFF8FF',
  WHITE: '#ffffff',
  PROGRESSBARBACKGROUND: ' #E2E8F0;',
  PRIMARYGREYCOLOR: '#E6E6E6',
  BACKGROUNDCOLOR: '#f0f0f0',
  NEUTRALGREYCOLOR: '#E8E8E8',
  BORDERCOLOR: '#B9B9B9',
  GRAYCOLOR: '#D7D7D7',
  TITLECOLOR: '#979797',
  LIGHTGREYCOLOR: '#8A8A8A',
  GREYCOLOR: '#686868',
  NAVGREYCOLOR: '#787878',
  SECONDARYCOLOR: '#606161',
  NEUTRALBLACKCOLOR: '#4A4A4A',
  PRIMARYBLACKCOLOR: '#1D1D1D',
  BLACKCOLOR: '#1A1A1A',
  STEPPERBLACKCOLOR: '#0a0c17',
  NOTIFICATIONBLACKCOLOR: '#0F172A',
  DARKBLACKCOLOR: '#0000000',
  SVGBLACKCOLOR: '#242424',
  INVITEBACKGROUNDCOLOR: '#4AA1E030',
  BACKGROUNDLIGHTRED: 'rgba(242, 73, 73, 0.19)',
  BOXSHADOWCOLOR: 'rgba(0, 0, 0, 0.1)',
  BACKGROUNDLIGHTORANGERUST: 'rgba(224, 173, 74, 0.14);',
  BACKGROUNDGREENCOLOR: 'rgba(65, 158, 106, 0.22)',
  PRIMARYBORDERCOLOR: 'rgba(0, 0, 0, 0.1)',
  ORANGERUST: '#F29C49',
  WARNING: '#F9C132',
  PROGRESS: '#F9C132',
  YELLOW: '#f9c51c',
  PRIMARYYELLOWCOLOR: '#FFCC00',
  PRIMARYGREENCOLOR: '#339900',
  SECONDARYGREENCOLOR: '#419E6A38',
  SUCCESS: '#419E6A',
  ERROR: '#D83232',
  RED: '#E04A65',
  LIGHTREDCOLOR: '#FC9595',
  ERRORBACKGROUND: '#FDDCDC',
}
