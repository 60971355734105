export const vehichleDetailsOptions = {
  ownerType: [
    { value: 'first owner', label: 'First Owner' },
    { value: 'second owner', label: 'Second Owner' },
    { value: 'third owner', label: 'Third Owner' },
  ],
  intercityOptions: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ],

  vehicleBrands: [
    'Maruti Suzuki',
    'Hyundai',
    'Tata Motors',
    'Mahindra & Mahindra',
    'Honda',
    'Toyota',
    'Ford',
    'Renault',
    'Volkswagen',
    'Nissan',
    'Skoda',
    'Chevrolet',
    'BMW',
    'Mercedes-Benz',
    'Audi',
    'Volvo',
    'Jaguar',
    'Land Rover',
    'Kia',
    'MG Motors',
    'Jeep',
    'Isuzu',
    'Datsun',
    'Fiat',
    'Mitsubishi',
    'Force Motors',
    'Ashok Leyland',
    'Premier',
    'MINI',
    'Porsche',
    'Lamborghini',
    'Bentley',
    'Rolls-Royce',
    'Ferrari',
    'Maserati',
    'SsangYong',
    'Tesla',
    'Haval',
    'Haima',
    'DFSK',
    'Foton',
    'Great Wall Motors',
    'Aston Martin',
    'Bugatti',
    'Lexus',
    'Lincoln',
    'McLaren',
    'Polestar',
    'KTM',
    'Harley-Davidson',
    'Royal Enfield',
    'Yamaha',
    'Honda',
    'Bajaj',
    'Hero MotoCorp',
    'TVS',
    'Suzuki',
    'Kawasaki',
    'Mahindra',
    'KTM',
    'Ducati',
    'Triumph',
    'Aprilia',
    'Benelli',
    'MV Agusta',
    'Husqvarna',
    'Jawa',
    'BMW Motorrad',
    'Indian Motorcycle',
    'Piaggio',
    'Vespa',
    'Lambretta',
    'Revolt Motors',
    'Ather Energy',
    'Okinawa',
    'Tork Motors',
    'Emflux Motors',
    'Ultraviolette',
    'Hero Electric',
    'Avan Motors',
    'Detel',
    'Ampere',
    'Evolet',
    'Jitendra Electric Vehicles',
  ],
}
