export const ISSUE_TYPES = {
  PTM01: 'Refund not received',
  PTM02: 'Underpaid',
  PTM03: 'Over paid',
  PTM04: 'Not paid',
  AGT01: 'Agent behavioral issue',
  AGT02: 'Buyer behavioral issue',
  FLM01: 'Wrong delivery address',
  FLM02: 'Delay in delivery',
  FLM03: 'Delayed delivery',
  FLM04: 'Packaging',
  FLM05: 'Buyer not found',
  FLM06: 'Seller not found',
  FLM07: 'Package info mismatch',
  ITM01: 'Missing items',
  ITM02: 'Quantity issue',
  ITM03: 'Item mismatch',
  ITM04: 'Quality issue',
  ORD01: 'Order not recieved',
  ORD02: 'Quality issue',
  ORD03: 'Delayed delivery',
}
