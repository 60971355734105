export const inputsFields = [
  {
    placeholder: '0',
    name: 'otp0',
  },
  {
    placeholder: '0',
    name: 'otp1',
  },
  {
    placeholder: '0',
    name: 'otp2',
  },
  {
    placeholder: '0',
    name: 'otp3',
  },
]
