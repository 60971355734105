enum ERROR_CODE {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  UN_AUTHORIZED_ACCESS = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
}
export default ERROR_CODE
