import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={31} height={29} fill="none" {...props}>
    <path
      fill="#196AAB"
      d="M24.834 9V6.25h-2.667V3.5h2.667V.75h2.667V3.5h2.666v2.75h-2.666V9h-2.667ZM3.501 28.25c-.734 0-1.362-.27-1.884-.808A2.685 2.685 0 0 1 .834 25.5V9c0-.756.261-1.404.784-1.943.523-.539 1.15-.808 1.883-.807h4.2l2.466-2.75h8v2.75h-6.833L8.901 9H3.5v16.5h21.333V13.125h2.667V25.5c0 .756-.262 1.404-.784 1.943-.523.539-1.15.808-1.883.807H3.501Zm10.666-4.813c1.667 0 3.084-.601 4.251-1.805 1.167-1.204 1.75-2.664 1.75-4.382 0-1.719-.584-3.18-1.751-4.383-1.168-1.204-2.584-1.805-4.25-1.805s-3.083.602-4.25 1.806c-1.167 1.204-1.75 2.664-1.75 4.382 0 1.719.584 3.18 1.751 4.384 1.167 1.203 2.584 1.804 4.25 1.803Zm0-2.75c-.933 0-1.722-.332-2.366-.996-.645-.665-.967-1.479-.967-2.441 0-.962.322-1.776.967-2.44.644-.665 1.433-.998 2.366-.998.934 0 1.723.333 2.367.997.644.665.967 1.479.967 2.441 0 .962-.323 1.776-.967 2.44-.644.665-1.433.997-2.367.997Z"
    />
  </svg>
)
export default SvgComponent
